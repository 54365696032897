<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <div class="top-nav-login pt-4 pl-5">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          pill
          class="float-right mr-5 btn-auth-non-login"
          style="font-weight: 400 !important;"
          :to="{name:'auth-register'}"
        >
          Sign up for free
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-light"
          class="float-right mr-1 btn-auth-non-login"
          style="color: #fff; font-weight: 400 !important;"
          :to="{name:'auth-login'}"
        >
          Login
        </b-button>

        <!-- <b-link
          class="brand-logo"
          href="/"
        >
          <b-img-lazy
            fluid
            :src="imgUrl"
            alt="logo SEOcloud"
            class="img-logo"
            width="340px"
            height="40px"
          />
        </b-link> -->
      </div>

      <div style="width: 100%;">
        <b-link
          class="brand-logo"
          href="/"
          style="position: inherit;"
        >
          <b-img-lazy
            fluid
            :src="imgUrl"
            alt="logo SEOcloud"
            class="img-logo"
            width="340px"
            height="40px"
            style="margin: auto; left: 0px !important; top: 180px;"
          />
        </b-link>
      </div>

      <b-col
        lg="12"
        class="d-flex px-2 p-lg-5 form-sign"
      >
        <b-col
          sm="8"
          md="6"
          lg="6"
          class="px-0 mx-auto"
          style="max-width: 480px !important;"
        >
          <b-tabs class="tabsLoginNavegation tabsRegister title-tab-nav-login" align="left">
            <b-tab
              title="Login"
              :active="true"
              style="color: #fff !important; font-weight: 300;"
              class="title-tab-nav-login"
            >
            </b-tab>
            <b-tab
              title="Sign up"
              :active="false"
              style="color: #fff !important; font-weight: 300;"
              @click.prevent="getSignUp"
              class="title-tab-nav-login"
            >
            </b-tab>
          </b-tabs>

          <b-card-title
            class="font-weight-bold color-text-white title-header-login"
            title-tag="h1"
            style="font-weight: 700; font-size: 28px; margin-top: 20px; line-height: 1.4;"
          >
            Login to your SEOcloud account
          </b-card-title>

          <validation-observer ref="loginForm">
            <b-form
              class="auth-login-form mt-2"
            >
              <b-form-group
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  vid="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    name="login-email"
                    style="height: 52px;"
                    trim
                    placeholder="Enter email"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group class="mb-50">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Enter password"
                      style="height: 52px;"
                    />
                    <b-input-group-append
                      is-text
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    >
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-link
                :to="{name:'auth-forgot-password', query: { email: userEmail }}"
                class=""
              >
                <small>Forgot password?</small>
              </b-link>

              <b-button
                type="submit"
                variant="primary"
                block
                class="mt-2 font-weight-bold btn-foot-login"
                style="font-weight: 400; border-radius: 39px; height: 52px; font-size: 20px;"
                @click.prevent="validationForm"
                :disabled="proccessInfo"
              >
                Log in
                <b-spinner small class="mb-25" v-if="proccessInfo" />
              </b-button>
            </b-form>
          </validation-observer>
          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center mt-1">
            <b-button
              variant="google"
              class="btn-foot-login-google"
              style="font-weight: 400; background: #f0f1f1; color: #676b85 !important; font-weight: bold; padding: 15px 30px !important; border-radius: 39px; height: 52px; font-size: 20px;"
              block
              @click="google"
            >
              <b-img
                :src="require('@/assets/images/icons/google.png')"
                style="height: 24px; margin-right: 15px; position: relative; top: -4px;"
                width="23px"
                height="24px"
                alt="img google"
              />
              <span>Log in with Google</span>
            </b-button>
          </div>

          <b-card-text class="text-center mt-2 mb-2 text-card-register ">
            <span style="color: #fff;">Need a free SEOcloud account?</span>
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;Sign up for free</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->

      <!-- Right Text-->
      <!-- <b-col
        lg="6"
        class="d-none d-lg-flex flex-column m-auto p-5"
      >
        <b-card-title
          class="mb-2 font-weight-bold color-text-white"
          style="font-weight: 400;"
          title-tag="h3"
        >
          The best web scanner to <span style="color: #c31bff;">resolve</span><br>
          technical and SEO problems<span style="color: #c31bff;">.</span>
        </b-card-title>
        <div class="w-100 d-lg-flex align-items-center justify-content-center">
          <b-img-lazy
            fluid
            :src="ghaphicsImg"
            alt="Login graph"
            width="800"
            height="445"
          />
        </div>
      </b-col> -->
      <!-- /Right Text-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
  BImgLazy, BCard, BCardBody, BModal, VBModal, BSpinner, BTabs, BTab,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required, email, password } from '@validations'
// import { required, minLength, password, confirmed, email, } from 'vuelidate/lib/validators'
// import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapState, mapMutations } from 'vuex'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { firebase, auth, db } from '@/firebase'
import useJwt from '@/auth/jwt/useJwt'
import router from '@/router'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BImgLazy,
    BCard,
    BCardBody,
    BModal,
    BSpinner,
    BTabs,
    BTab,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  // mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/logo/logo-seocloud.svg'),
      ghaphicsImg: require('@/assets/images/banner/ghaphics.svg'),
      proccessInfo: false,
      // ghaphics.svg

      // validation rules
      email,
      required,
      password,
      passwordFieldType: 'password'
      // confirmed,
    }
  },
  metaInfo: {
    meta: [
      { name: 'title', content: 'Login to your SEOcloud account' },
    ],
  },
  computed: {
    ...mapState(['usuario']),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/logo/logo-seocloud.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    ...mapMutations(['nuevoUsuario']),
    getSignUp() {
      this.$router.push({name:'auth-register'})
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    google() {
      const provider = new firebase.auth.GoogleAuthProvider()
      this.ingresar(provider)
    },
    async ingresar(provider) {
      // ingreso con cuenta de google
      firebase.auth().languageCode = 'en'

      try {
        // Ingreso Usuario
        const result = await firebase.auth().signInWithPopup(provider)
        const { user } = result

        const doc = await db.collection('Users').doc(user.uid).get()

        // Construir usuario
        const usuario = {
          nombre: doc.exists ? doc.data().nombre : user.displayName,
          email: user.email,
          uid: user.uid,
          foto: user.photoURL,
          role: 'user',
          status: 'active',
          typeRegister: 'Regular register page',
          totalSites: 10,
          ability: [
            {
              action: 'manage',
              subject: 'all',
            },
          ],
        }

        // el usuario no existe
        if(!doc.exists) {
          // Guardar el usuario
          await db.collection('Users').doc(usuario.uid).set(
            usuario,
          )
          setTimeout(() => {
            db.collection('Projects').add({
              name: 'My sites',
              color: '#d1d1d1',
              isMySites: true,
              numberSites: 0,
              numberTeamMembers: 0,
              owner: {
                nombre: usuario.nombre,
                email: usuario.email,
                uid: usuario.uid,
                foto: '',
              },
              teamMembers: []
            })
            .then(docRef => {
              // update the uid of the project
              db.collection('Projects').doc(docRef.id).update({
                id: docRef.id,
              })
              .catch(error => {
                console.error(error)
              })
      
              // update teams owner
              db.collection('Users').doc(usuario.uid).update({
                teams: firebase.firestore.FieldValue.arrayUnion(docRef.id),
                updated: Date.now(),
                mySitesId: docRef.id
              })
              .catch(error => {
                console.error(error)
              })
      
              db.collection('Users').doc(usuario.uid).collection('projects').doc(docRef.id).set({
                id: docRef.id,
                name: 'My sites',
                color: '#d1d1d1',
                isMySites: true,
                numberSites: 0,
                // sites: site ? site : '',
                numberTeamMembers: 0,
                owner: {
                  nombre: usuario.nombre,
                  email: usuario.email,
                  uid: usuario.uid,
                  foto: '',
                },
                teamMembers: []
              })
              .catch(error => {
                console.error(error)
              })
            })
          }, 500)

          // db.collection('projects').get()
          // .then(projects => {
          //   projects.forEach(project => {
          //     db.collection('projects').doc(project.id).collection('members').where('uid', '!=', 'resume').get()
          //     .then(invitationsProject => {
          //       if(invitationsProject.size >= 1) {
          //         invitationsProject.forEach(invitation => {
          //           if(invitation.data().uid) {
          //             // 
          //           } else {
          //             db.collection('Users').where('email', '==', invitation.data().email).get()
          //             .then(queryUser => {
          //               if(queryUser.size >= 1) {
          //                 queryUser.forEach(userDoc => {
          //                   db.collection('projects').doc(project.id).collection('members').doc(invitation.id).update({
          //                     imgU: userDoc.data().foto ? userDoc.data().foto : '',
          //                     name: userDoc.data().nombre,
          //                     uid: userDoc.id
          //                   })
          //                   // .then(() => console.log('updated'))
          //                 })
          //               }
          //             })
          //           }
          //         })
          //       }
          //     })
          //   })
          // })
        }

        this.nuevoUsuario(usuario)
        localStorage.setItem('firstLogin', Date.now())
        localStorage.setItem('ll', Date.now())
        localStorage.setItem('forgetNotification', true)

        // obtener ruta
        this.$ability.update(usuario.ability)
        // if(doc.exists && (doc.data().teams && doc.data().teams.length > 1)) {
          this.$router.replace(getHomeRouteForLoggedInUser(doc.exists && (doc.data().teams && doc.data().teams.length > 1) ? doc.data().role : 'staff'))
        // } else {
          // console.log(1)
          // this.$router.push({ name: 'sites-no-project' })
        // }
        setTimeout(() => {
          this.proccessInfo = false
        }, 1500)
      } catch (error) {
        this.proccessInfo = false
        console.log(error)
      }
    },
    async ingresoUsuario(usuario) {
      // ingreso con email y contraseña
      try {
        const signIn = await auth.signInWithEmailAndPassword(usuario.email, usuario.password)

        const doc = await db.collection('Users').doc(signIn.user.uid).get()

        // el usuario ya existe
        if(doc.exists) {
          // login condition
          const UsuarioLogueado = {
            email: signIn.user.email,
            uid: signIn.user.uid,
          }

          const userData = doc.data()
          // Construir usuario
          this.nuevoUsuario(userData)
          localStorage.setItem('firstLogin', Date.now())
          localStorage.setItem('ll', Date.now())
          localStorage.setItem('forgetNotification', true)

          this.$ability.update(userData.ability)

          this.$router.replace(getHomeRouteForLoggedInUser(doc.exists && (doc.data().teams && doc.data().teams.length > 1) ? userData.role : 'staff'))
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Welcome back ${userData.nombre}`,
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            })
            setTimeout(() => {
              this.proccessInfo = false
            }, 1500)
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'error',
              text: 'User does not exist',
            },
          })
          setTimeout(() => {
            this.proccessInfo = false
          }, 1500)
        }
      } catch (error) {
        if(error.code === 'auth/internal-error') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'error',
              text: 'Invalid email or password.',
            },
          })
          setTimeout(() => {
            this.proccessInfo = false
          }, 1500)
        } else if(error.code === 'auth/wrong-password') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'error',
              text: 'Incorrect password.',
            },
          })
          setTimeout(() => {
            this.proccessInfo = false
          }, 1500)
        } else if (error.code === 'auth/user-not-found') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'error',
              text: 'Invalid email or password',
            },
          })
          setTimeout(() => {
          this.proccessInfo = false
          }, 1500)
        } else {
          // console.log(error)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'error',
              text: 'Invalid email or password',
            },
          })
          setTimeout(() => {
            this.proccessInfo = false
          }, 1500)
        }
      }
    },
    validationForm() {
      this.$refs.loginForm.validate().then(success => {
        if(success) {
          this.proccessInfo = true
          this.ingresoUsuario({ email: this.userEmail, password: this.password })
        }
      })
    },
  },
  created() {
    this.proccessInfo = false
    localStorage.removeItem('filtShared')
  }
}
</script>

<style>
@media  (min-width: 0) and (max-width: 991px) {
  .btn-auth-non-login {
    display: none !important;
  }
}

/* .lnkHv:hover {
  color: #f4d6ff !important;
} */
.form-sign {
  padding-top: 9vh !important;
}
@media only screen and (min-width: 600px) {
  .form-sign {padding-top: 9vh !important;}
}
@media only screen and (min-width: 2300px) {
  .form-sign {padding-top: 32vh !important;}
}
@media only screen and (min-width: 3282px) {
  .form-sign {padding-top: 36vh !important;}
}
@media only screen and (min-width: 4100px) {
  .form-sign {padding-top: 38vh !important;}
}
@media only screen and (min-width: 5400px) {
  .form-sign {padding-top: 42vh !important;}
}
@media only screen and (max-width: 800px) {
  .title-tab-nav-login a {
    font-size: 17.1px !important;
  }
  .title-header-login {
    font-size: 22.4px !important;
  }
  .btn-foot-login {
    font-size: 18px !important;
  }
  .btn-foot-login-google {
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 500px) {
  .btn-foot-login-google {
    font-size: 14px !important;
    padding-top: 17px !important;
  }
}
@media only screen and (max-width: 273px) {
  .btn-foot-login-google {
    font-size: 12px !important;
    padding-top: 19px !important;
  }
}
@media only screen and (min-height: 1700px) {
  .img-logo {top: 180px !important;}
}
/* @media only screen and (min-height: 1500px) { */
/* @media  (min-width: 871px) and (max-width: 1200px) {
  .img-logo {top: 120px !important;}
} */
@media  (min-height: 668px) and (max-height: 870px) {
  .img-logo {top: 80px !important;}
}
@media  (min-height: 0) and (max-height: 667px) {
  .img-logo {top: 30px !important;}
}
.tabsLoginNavegation
.nav-tabs
.nav-link.active {
  color: #fff !important;
  font-family: 'Hurme Geometric Sans 3';
  font-weight: 400 !important;
  padding-bottom: 4px;
  font-size: 19px;
  background: none !important;
}
.tabsLoginNavegation
.nav-tabs
.nav-link {
  font-weight: 300 !important;
}
.tabsLoginNavegation
.nav-tabs
.nav-link.active::after {
  background: transparent linear-gradient(90deg, #7838ff 0%, #30CDFF 100%) !important;
}
.tabsLoginNavegation
.nav-tabs
.nav-link {
  color: #fff !important;
  /* font-family: ; */
  padding-bottom: 0px;
  font-size: 19px;
}
body {
  background: url("https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/stripes-bkg.png?alt=media&token=174016c2-d520-4123-b4b7-bead206719ab") 0% 0% no-repeat padding-box,linear-gradient(115deg, #181e38 47%, #7838ff 180%) !important;
}
.modal .modal-header .close {
  transform: none;
  box-shadow: none;
  border-radius: unset;
  background: none;
}
.modal .modal-header .close:hover {
  transform: translate(2px, 2px);
}
.top-nav-login {
  /* background-color: #7838ff; */
  /* background-image: url(/assets/images/pattern-8d0f59e0f30b8e1261b9e36e843460eb.png); */
  /* background-repeat: repeat; */
  margin-right: 50px;
  border: none;
  height: 55px;
  top: 0px;
  position: absolute;
  width: 100%;
}
.img-logo {
  width: 340px;
  height: 40px;
  position: relative;
  top: 25px;
  left: 20px;
}
.brand-logo-svg{fill: white;height: 38px;}
.account-body {
  position: relative;
  padding: 35px 30px 10px;
  margin-bottom: 1em;
  color: #444;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-box-shadow: 1px 1px 1px rgba(0,0,0,.15);
  box-shadow: 1px 1px 1px rgba(0,0,0,.15);
  border-radius: 4px;
  width: 450px
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.text-card-register {
  font-size: 15px;
}
</style>
